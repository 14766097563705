/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { GateSelectors } from '@/store'
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

/**
 * For a given entity type, will return a list of pre-defined internal entity
 * types pertaining to it.
 *
 * @remarks
 * These lists serve as the source-of-truth for which fields are visible to
 * internal users.
 * - Internal users are defined by the "platform support" permission.
 * - Any new fields added to an API will be visible by default.
 * - To hide a field, update this list.
 *
 * @param entityType - string representing an entity type
 *
 * @returns an array of entity types considered to be internal that are relevant to the given broader entity type
 */
export const getInternalListByEntity = (entityType: EntityUtils.EntityType): string[] => {
  // prettier-ignore
  const _perspectiveBase = [
    'perspective',
    // "perspective__name",
    "perspective_id",
    // "perspective_is_targeted",
    // "perspective_type",
  ]

  const _targetBase = [
    'attack_note',
    'authority',
    'authority_distance',
    'authority_override',
    'authorization_state',
    'authorizing_policies',
    'banners_data_hash',
    'banners_uuid',
    'cert_data_hash',
    'cert_uuid',
    'cpe',
    'deleted',
    'detection_relevance',
    'headers_data_hash',
    'headers_uuid',
    'hostname_id',
    'id',
    'ip',
    'ip_id',
    'lens_id',
    'lens_view',
    'org_id',
    'perspective',
    'poc_email',
    'poc_id',
    'priority_impact_factor',
    'priority_status_factor',
    'priority_tags_factor',
    'private_weakness',
    'protocol',
    'public_weakness',
    'reference',
    'screenshot_data_hash',
    'screenshot_uuid',
    'service_description',
    'service_id',
    'target_confidence',
    'target_num_authorized_detections',
    'thumbnail_data_hash',
    'thumbnail_uuid',
  ]

  switch (entityType) {
    case 'activity_configuration': {
      return ['activity_type_description', 'configuration_id', 'entities_count', 'id', 'type', 'version', 'stealth']
    }

    case 'activity_instance': {
      return [
        'configuration__description',
        'configuration__id',
        'configuration__version_id',
        'count__entities_updated',
        'count__relationships_deleted',
        'id',
        'instance__most_recent_instance_id',
        'instance__perspective',
        'instance__perspective__id',
        'instance__perspective__name',
        'instance__perspective_is_targeted',
        'instance__perspective_type',
        'matching_entity__asset',
        'matching_entity__detection',
        'matching_entity__email',
        'matching_entity__hostname',
        'matching_entity__id',
        'matching_entity__ip',
        'matching_entity__network',
        'matching_entity__target',
        'matching_entity__term',
        // @TODO Assets
        // matching_entity__asset
        'org_id',
        'traffic_destination_detail',
      ]
    }

    case 'artifact': {
      return ['id']
    }

    case 'target': {
      return [
        ..._targetBase,
        ..._perspectiveBase,
        'first_seen',
        'last_seen',
        'validated_vulnerabilities_detection',
        'validated_vulnerabilities_detection_count',
        'attack_authorization',
      ]
    }

    case 'detection_target':
    case 'topLevelDetection': {
      return [
        ..._targetBase,
        ..._perspectiveBase,
        'target_first_seen',
        'target_last_seen',
        'validated_vulnerabilities_target',
        'validated_vulnerabilities_target_count',
      ]
    }

    case 'hostname': {
      return [
        ..._perspectiveBase,
        'authority',
        'authority_distance',
        'authority_override',
        'deleted',
        'id',
        'is_prime',
        'lens_id',
        'lens_view',
        'max_confidence',
        'name_type',
        'only_in_review_targets',
        'org_id',
        'priority_impact_factor',
        'priority_status_factor',
        'priority_tags_factor',
      ]
    }

    case 'implant': {
      return ['bart_id', 'bits', 'created_on', 'id', 'method', 'next_checkin', 'org_id', 'os', 'osver']
    }

    case 'ip': {
      return [
        ..._perspectiveBase,
        'authority_distance',
        'authority_override',
        'authority',
        'country',
        'deleted',
        'id',
        'ip',
        'latitude',
        'lens_id',
        'lens_view',
        'location',
        'longitude',
        'only_in_review_targets',
        'org_id',
        'priority_impact_factor',
        'priority_status_factor',
        'priority_tags_factor',
        'radius',
      ]
    }

    case 'network': {
      return [
        ..._perspectiveBase,
        'authority_distance',
        'authority_override',
        'authority',
        'deleted',
        'id',
        'lens_id',
        'lens_view',
        'max_confidence',
        'network_str',
        'only_in_review_targets',
        'org_id',
        'priority_impact_factor',
        'priority_status_factor',
        'priority_tags_factor',
      ]
    }

    // @TODO: there's something for social AND POC - let's revisit
    case 'poc': {
      return ['authority', 'authority_distance', 'authority_override']
    }

    case 'policy': {
      return ['id']
    }

    case 'redirector': {
      return ['bart_id', 'deleted', 'id', 'org_id', 'remote_row_id', 'updated_on']
    }

    case 'report': {
      return ['id', 'org_id', 'report_data', 'version']
    }

    case 'runbook': {
      return [
        'comment',
        'deleted',
        'dst_search',
        'guidance',
        'id',
        'implant_ids',
        'implant_uid',
        'objective',
        'org_id',
        'perspective_metadata',
        'results',
        'runbook_id',
        'service_description',
        'src_search',
        'trigger',
      ]
    }

    case 'savedViews': {
      return ['id']
    }

    case 'service': {
      return [
        ..._perspectiveBase,
        'attack_note',
        'authority_distance',
        'authority_override',
        'authority',
        'cpe',
        'deleted',
        'id',
        'lens_id',
        'lens_view',
        'org_id',
        'private_weakness',
        'public_weakness',
      ]
    }

    case 'serviceRule': {
      return ['id']
    }

    case 'social': {
      return [
        ..._perspectiveBase,
        'id',
        'address',
        'authority',
        'authority_distance',
        'authority_override',
        'authorization_input',
        'city',
        'company_name',
        'country',
        'deleted',
        'details',
        'domain',
        'email_type',
        'lens_id',
        'lens_view',
        'org_id',
        'person_middle_name',
        'person_name',
        // 'perspective',
        'phone',
        'postal_code',
        'priority_impact_factor',
        'priority_status_factor',
        'priority_tags_factor',
        'role',
        'seniority',
        'state',
        'sub_role',
        'tld',
        'username',
      ]
    }

    case 'authorization_policy': {
      return [
        'actions',
        'detection_criteria',
        'entity_types',
        'expires_at',
        'filter_data',
        'is_deleted',
        'notes',
        'org_id',
        'version',
      ]
    }

    case 'bdo_detection': {
      return [
        ..._perspectiveBase,
        // 'affiliation_state',
        'artifact__banner_sha',
        'artifact__certificate_sha',
        'artifact__header_sha',
        'artifact__screenshot_sha',
        'artifact__thumbnail_sha',
        'authority',
        'authority_distance',
        'authority_override',
        // 'authorization_state',
        // 'authorizing_policies',
        // 'confidence',
        'consolidated_target__ids',
        // 'consolidated_target__names',
        // 'detection_criteria',
        // 'detection_criteria__hostname',
        'detection_criteria__ip',
        // 'detection_criteria__ip_str',
        // 'detection_criteria__path',
        // 'detection_criteria__port',
        'detection_criteria__port_str',
        // 'detection_criteria__protocols',
        'discovered_service__ids',
        // 'discovered_service__names',
        'discovery_path',
        'entity_type',
        // 'first_seen',
        // 'id',
        'id__hostname',
        'id__ip',
        'id__network',
        'id__poc',
        'id__port',
        // 'last_seen',
        'lens_uuid',
        'lens_view',
        'org_id',
        // 'perspective_name',
        'perspective_uuid',
        // 'status',
        'user_comments',
        'user_comments_uuids',
        'user_tags',
        // 'validated_vulnerabilities',
        // 'validated_vulnerabilities_count',

        // @TODO: These shouldn't be in the openapi spec
        'detection_creator_type',
        'type',
        'affiliation',
      ]
    }

    case 'asset': {
      return ['id']
    }

    case 'exception_policy': {
      return ['id']
    }

    case 'perspective': {
      return []
    }

    case 'source': {
      return ['id']
    }

    case 'bar':
    case 'blueprint':
    case 'characteristicRule':
    case 'globalArtifact':
    case 'globalHostname':
    case 'globalIps':
    case 'globalNetwork':
    case 'globalService':
    case 'integrations': // not a page with a table
    case 'organization':
    case 'peer':
    case 'term':
    case 'serviceSuggestion': {
      return []
    }

    case 'action':
    case 'applicable_activity':
    case 'entity_for_activity_instance':
    case 'hostnameForIp':
    case 'ipForHostname':
    case 'ipForNetwork':
      throw new RandoriEntityTypeError({ entityType })

    default:
      throw new ExhaustiveError(entityType)
  }
}

// @TODO: Locate usages, and eliminate
//
// This can simply be `getInternalListByEntity`
export const getInternalListByEntityAndFlags = (
  entityType: EntityUtils.EntityType,
  _staticFlags: ReturnType<typeof GateSelectors.staticFlags>,
): string[] => {
  const list = getInternalListByEntity(entityType)

  if (entityType === 'topLevelDetection') {
    return list
      .filter((filter) => filter !== 'authorizing_policies')
      .filter((filter) => filter !== 'detection_authorization_state')
      .filter((filter) => filter !== 'target_num_authorized_detections')
  }

  if (entityType === 'detection_target') {
    return list
      .filter((filter) => filter !== 'authorizing_policies')
      .filter((filter) => filter !== 'detection_authorization_state')
      .filter((filter) => filter !== 'target_num_authorized_detections')
  }

  if (entityType === 'target') {
    return list.filter((filter) => filter !== 'target_num_authorized_detections')
  }

  return list
}
