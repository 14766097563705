/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ReportResponse, ReportsResponse } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type ReportApi = {
  getReports: (query: string) => Promise<ReportsResponse>
  getReport: (id: string) => Promise<ReportResponse>
}

export function reportApiFactory(http: HttpClient): ReportApi {
  const baseURL = '/recon/api/v1'

  return {
    getReports(query) {
      return http.get(`${baseURL}/report${query}`).then(CodecUtils.throwValidate(ReportsResponse, 'ReportsResponse'))
    },

    getReport(id: string) {
      return http.get(`${baseURL}/report/${id}`).then(CodecUtils.throwValidate(ReportResponse, 'ReportResponse'))
    },
  }
}
