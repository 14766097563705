/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullable, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const SourceType = t.keyof({
  SOURCE_TYPE_UNSPECIFIED: null,
  SOURCE_TYPE_ATTACK_IMPLANT: null,
})
export type SourceType = t.TypeOf<typeof SourceType>

export const ImplantBakeStatus = t.keyof({
  BAKE_STATUS_TYPE_UNSPECIFIED: null,
  BAKE_STATUS_TYPE_COMPLETE: null,
  BAKE_STATUS_TYPE_FAILED: null,
  BAKE_STATUS_TYPE_PENDING: null,
})
export type ImplantBakeStatus = t.TypeOf<typeof ImplantBakeStatus>

export const ImplantType = t.keyof({
  IMPLANT_TYPE_UNSPECIFIED: null,
  IMPLANT_TYPE_LOCAL: null,
  IMPLANT_TYPE_NETWORK: null,
})
export type ImplantType = t.TypeOf<typeof ImplantType>

export const OsType = t.keyof({
  OS_TYPE_UNSPECIFIED: null,
  OS_TYPE_WIN32: null,
  OS_TYPE_LINUX: null,
})
export type OsType = t.TypeOf<typeof OsType>

export const Architecture = t.keyof({
  ARCHITECTURE_TYPE_UNSPECIFIED: null,
  ARCHITECTURE_TYPE_X86: null,
  ARCHITECTURE_TYPE_X64: null,
})
export type Architecture = t.TypeOf<typeof Architecture>

// Note: the sources we get back from the perspective service uses camelCase keys, but we convert those
// keys to snake case in the sagas to better serve our columns and cell rendering
export const SourceFromPerspectiveService = t.type({
  id: UUID,
  sourceType: SourceType,
  name: t.string,
  defaultPerspectiveId: UUID,
  deleted: t.boolean,
  createTime: nullable(DateString),
  updateTime: nullable(DateString),
  // 'opaque' json object 'black box' - tighten as much as possible as BE gets more fleshed out
  parameters: t.union([
    t.record(t.string, t.string),
    t.record(t.string, t.undefined),
    t.record(t.string, ImplantType),
    t.null,
  ]),
})

type _SourceFromPerspectiveService = t.TypeOf<typeof SourceFromPerspectiveService>
export type SourceFromPerspectiveService = _SourceFromPerspectiveService

export const Source = t.type({
  id: UUID,
  source_type: SourceType,
  name: t.string,
  // this id will be used to make additional call to get perspective details
  default_perspective_id: UUID,
  deleted: t.boolean,
  create_time: nullable(DateString),
  update_time: nullable(DateString),
  // 'opaque' json object 'black box' - tighten as much as possible as BE gets more fleshed out
  parameters: t.union([
    t.record(t.string, t.string),
    t.record(t.string, t.undefined),
    t.record(t.string, ImplantType),
    t.null,
  ]),
})

type _Source = t.TypeOf<typeof Source>
export type Source = _Source

export const SourceNormalized = t.type({
  id: UUID,
  source_type: SourceType,
  name: t.string,
  default_perspective_id: UUID,
  deleted: t.boolean,
  create_time: nullable(DateString),
  update_time: nullable(DateString),
})
export type SourceNormalized = t.TypeOf<typeof SourceNormalized>

export const SourcesResponse = t.type({ sources: t.array(SourceFromPerspectiveService) })
export type SourcesResponse = t.TypeOf<typeof SourcesResponse>

export const SourceResponse = SourceFromPerspectiveService
export type SourceResponse = t.TypeOf<typeof SourceResponse>

export const CreateImplantPayload = t.type({
  source_name: t.string,
  implant_type: ImplantType,
  os_type: OsType,
  architecture: Architecture,
  perspective_id: UUID,
  source_note: t.string,
})
export type CreateImplantPayload = t.TypeOf<typeof CreateImplantPayload>

export const CreateImplantResponse = t.type({
  sourceId: UUID,
  sourceName: t.string,
  implantType: ImplantType,
  osType: OsType,
  architecture: Architecture,
  perspectiveId: UUID,
  sourceNote: t.string,
})
export type CreateImplantResponse = t.TypeOf<typeof CreateImplantResponse>

export const RedirectorForSource = t.type({
  id: t.string,
  name: nullable(t.string),
  externalIp: t.string,
  vpnNetwork: nullable(t.string),
  healthy: t.boolean,
  usage: t.array(t.string),
  type: nullable(t.string),
  jsonDetails: nullable(t.string),
  deleted: t.boolean,
})
export type RedirectorForSource = t.TypeOf<typeof RedirectorForSource>

export const RedirectorsForSourceResponse = t.type({
  redirectors: t.array(RedirectorForSource),
})
export type RedirectorsForSourceResponse = t.TypeOf<typeof RedirectorsForSourceResponse>
