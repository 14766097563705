/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isAfter, parseISO, subMonths, subWeeks, subYears } from 'date-fns'
import qs from 'query-string'
import { filter, head, pathOr, prop, propEq, sortBy } from 'ramda'

import * as Codecs from '@/codecs'
// @TODO: These should be in a utility, i.e. the r-date util
import {
  mungeEntityType,
  oneMonthInterval,
  oneWeekInterval,
  threeMonthInterval,
  yearInterval,
} from '@/components/attack-graph/graph-container'
import { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'
import * as EntityUtils from '@/utilities/r-entity'
import { RandoriEntityTypeError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

export const sortByTT = sortBy(prop('target_temptation'))

export function createQuery(entity: string) {
  return {
    condition: 'AND',
    rules: [
      {
        field: 'table.name',
        operator: 'equal',
        value: entity,
      },
    ],
  }
}

export const queryName = createQuery

type Record = {
  current: boolean
  name: string
  value: number
}

export function findEntityCount(
  entity: string,
  isHighQuery: boolean,
  isPrioQuery: boolean,
  deltas: Array<{ data: Record[] }>,
) {
  const start = pathOr([], [0, 'data'], deltas)
  const end = pathOr([], [1, 'data'], deltas)

  const entityName = translateEntityName(entity, isHighQuery, isPrioQuery)

  const startValue = pathOr(0, [0, 'value'], findEntityArr(start, entityName))
  const endValue = pathOr(0, [0, 'value'], findEntityArr(end, entityName))

  return endValue - startValue
}

export function findEntityArr(arr: Array<Record>, name: string) {
  return filter(propEq('name', name), arr)
}

export function translateEntityName(entity: string, isHighQuery: boolean, isPrioQuery: boolean) {
  let _name = entity

  if (isHighQuery && !entity.includes('top')) {
    _name = isPrioQuery ? `top_prio_${entity}` : `top_${entity}`
  }

  return _name
}

export function interpolateEntityToStatName(
  entity: EntityUtils.EntityType,
  isHighQuery: boolean,
  isPrioQuery: boolean,
) {
  const _entity = mungeEntityType(entity)

  if (isHighQuery && !entity.includes('top')) {
    return isPrioQuery ? `top_prio_${_entity}` : `top_${_entity}`
  } else {
    return _entity
  }
}

export function filterData(arr: any[], interval: number) {
  // these need to be separate cases to account for the different time intervals / comparators we are diffing against for deltas
  return arr.map((el: any) => {
    switch (interval) {
      case oneWeekInterval:
        return isAfter(parseISO(el.time), subWeeks(new Date(), 1)) ? { ...el, value: 0 } : el

      case oneMonthInterval:
        return isAfter(parseISO(el.time), subMonths(new Date(), 1)) ? { ...el, value: 0 } : el

      case threeMonthInterval:
        return isAfter(parseISO(el.time), subMonths(new Date(), 3)) ? { ...el, value: 0 } : el

      case yearInterval:
        return isAfter(parseISO(el.time), subYears(new Date(), 1)) ? { ...el, value: 0 } : el

      default:
        return el
    }
  })
}

// eslint-disable-next-line complexity
export function getApiSummaryMethod(entityType: EntityUtils.EntityType, io: MiddlewaresIO) {
  switch (entityType) {
    case 'artifact':
      return io.api.recon.getArtifacts
    case 'hostname':
      return io.api.recon.getHostnames
    case 'implant':
      return io.api.attack.getImplants
    case 'ip':
      return io.api.recon.getIps
    case 'network':
      return io.api.recon.getNetworks
    case 'policy':
      return io.api.recon.getPolicies
    case 'redirector':
      return io.api.attack.getRedirectors
    case 'runbook':
      return io.api.attack.getRunbooks
    case 'service':
      return io.api.recon.getServices
    case 'social':
      return io.api.recon.getSocialEntities
    case 'target':
      return io.api.recon.getTargets
    case 'topLevelDetection':
      return io.api.recon.getDetectionTargets
    case 'bdo_detection':
      return io.api.detection.getDetections

    default:
      throw new RandoriEntityTypeError({ entityType: entityType })
  }
}

export function getIdFieldForTarget(type: EntityUtils.EntityType) {
  switch (type) {
    case 'hostname':
      return 'hostname_id'

    case 'ip':
      return 'ip_id'

    case 'network':
      return 'ip_id'

    case 'service':
      return 'service_id'

    default:
      throw new RandoriEntityTypeError({ entityType: type })
  }
}

export const getQueryFnByEntityType = (entityType: EntityUtils.EntityType, api: MiddlewaresIO['api']) => {
  // prettier-ignore
  switch (entityType) {
    case 'bdo_detection': return (_query: string) => api.detection.getDetections(get(_query, QueryString))
    case 'detection_target': return (_query: string) => api.target.getTargets(get(_query, QueryString))
    case 'hostname': return (_query: string) => api.hostname.getHostnames(get(_query, QueryString))
    case 'ip': return (_query: string) => api.ip.getIps(get(_query, QueryString))
    case 'network': return (_query: string) => api.network.getNetworks(get(_query, QueryString))
    case 'service': return (_query: string) => api.service.getServices(get(_query, QueryString))
    case 'social': return api.recon.getSocialEntities
    case 'target': return (_query: string) => api.target.getTargets(get(_query, QueryString))
    case 'topLevelDetection': return (_query: string) => api.detection.getDetections(get(_query, QueryString))

    default:
      throw new RandoriEntityTypeError({ entityType: entityType })
  }
}

export const getStatsQueryParametersFactory = (
  limit: number,
  sort: string,
  fieldConfig: { range: [string, string]; isHighQuery: boolean; isPrioQuery: boolean },
) => {
  return (entityType: EntityUtils.EntityType) => {
    const q = CrudQueryUtils.serializeQ({
      condition: 'AND' as const,
      rules: [
        queryName(interpolateEntityToStatName(entityType, fieldConfig.isHighQuery, fieldConfig.isPrioQuery)),
        {
          field: 'table.time',
          id: 'table.time',
          input: 'text',
          operator: 'between',
          type: 'datetime',
          value: fieldConfig.range,
        },
        {
          field: 'table.time',
          id: 'table.time',
          input: 'text',
          operator: 'is_not_null',
          type: 'datetime',
          value: null,
        },
      ],
    })

    return qs.stringify({
      limit,
      q,
      sort,
    })
  }
}

export const getStat = (list: Codecs.Statistics[]) => {
  const firstRecord = head(list)

  if (isNotNil(firstRecord) && isNotNil(firstRecord.value)) {
    return firstRecord.value
  } else {
    return 0
  }
}

export const getStatDelta = (_latest: Codecs.Statistics[], _oldest: Codecs.Statistics[]) => {
  const latest = getStat(_latest)
  const oldest = getStat(_oldest)

  return latest - oldest
}
