/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { jwtDecode as decode } from 'jwt-decode'
import { head } from 'lodash/fp'
import { prop, propOr } from 'ramda'
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, select, takeEvery } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import { getPendoConfig } from '@/components/embed/pendo'
import * as Store from '@/store'
import * as _AuthActions from '@/store/actions/auth/auth.actions'
import * as _ReconActions from '@/store/actions/recon/recon.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { isNotNil } from '@/utilities/is-not'
import { noop } from '@/utilities/noop'
import { parseAuth } from '@/utilities/parse-auth'

import { _FEATURES_FETCH, _USERS_FETCH } from '../organization'
import { _PREFERENCES_GET } from '../preferences'
import { _SAVED_VIEWS_FETCH } from '../recon/recon.sagas'

// ---------------------------------------------------------------------------

export function* watchAccount(io: MiddlewaresIO) {
  yield takeEvery(Store.AuthActions.TypeKeys.ACCEPT_TOS, Catch.deferredAction(_ACCEPT_TOS, io))
  yield takeEvery(Store.AuthActions.TypeKeys.API_TOKENS_GET, Catch.deferredAction(_getApiTokens, io))
  yield takeEvery(Store.AuthActions.TypeKeys.API_TOKEN_DELETE, Catch.deferredAction(_deleteApiToken, io))
  yield takeEvery(Store.AuthActions.TypeKeys.API_TOKEN_POST, Catch.deferredAction(_postApiToken, io))
  yield takeEvery(Store.AuthActions.TypeKeys.CHANGE_PASSWORD, Catch.deferredAction(_changePassword, io))
  yield takeEvery(Store.AuthActions.TypeKeys.CONFIRM_SSO, Catch.deferredAction(_CONFIRM_SSO, io))
  yield takeEvery(Store.AuthActions.TypeKeys.FETCH_USER, Catch.deferredAction(_FETCH_USER, io))
  yield takeEvery(Store.AuthActions.TypeKeys.GET_WORKATO_JWT, Catch.deferredAction(_GET_WORKATO_JWT, io))
  yield takeEvery(Store.AuthActions.TypeKeys.INVITE_USER, Catch.deferredAction(_INVITE_USER, io))
  yield takeEvery(Store.AuthActions.TypeKeys.LOG_IN, Catch.deferredAction(_logIn, io))
  yield takeEvery(Store.AuthActions.TypeKeys.LOG_IN_OTP, Catch.deferredAction(_logInOTP, io))
  yield takeEvery(Store.AuthActions.TypeKeys.LOG_OUT, Catch.deferredAction(_logOut, io))
  yield takeEvery(Store.AuthActions.TypeKeys.ORG_INIT, Catch.deferredAction(_ORG_INIT, io))
  yield takeEvery(Store.AuthActions.TypeKeys.GET_PENTEST_METADATA, Catch.deferredAction(_GET_PENTEST_METADATA, io))
  yield takeEvery(Store.AuthActions.TypeKeys.RECOVER_SSO, Catch.deferredAction(_RECOVER_SSO, io))
  yield takeEvery(Store.AuthActions.TypeKeys.REMOVE_USER, Catch.deferredAction(_REMOVE_USER, io))
  yield takeEvery(Store.AuthActions.TypeKeys.REQUEST_PENTEST_POST, Catch.deferredAction(_REQUEST_PENTEST_POST, io))
  yield takeEvery(Store.AuthActions.TypeKeys.RESET_OTP_FOR_USER, Catch.deferredAction(_resetOTPForUser, io))
  yield takeEvery(Store.AuthActions.TypeKeys.RESET_USER_PASSWORD, Catch.deferredAction(_RESET_USER_PASSWORD, io))
  yield takeEvery(Store.AuthActions.TypeKeys.SET_OTP, Catch.deferredAction(_setOTP, io))
  yield takeEvery(Store.AuthActions.TypeKeys.SET_PASSWORD, Catch.deferredAction(_setPassword, io))
  yield takeEvery(Store.AuthActions.TypeKeys.SSO_DELETE, Catch.deferredAction(_SSO_DELETE, io))
  yield takeEvery(Store.AuthActions.TypeKeys.SSO_FORCE_RELOG, Catch.deferredAction(_SSO_FORCE_RELOG, io))
  yield takeEvery(Store.AuthActions.TypeKeys.SSO_SEND_EMAIL, Catch.deferredAction(_SSO_SEND_EMAIL, io))
  yield takeEvery(Store.AuthActions.TypeKeys.UPDATE_USER, Catch.deferredAction(_UPDATE_USER, io))
  yield takeEvery(Store.AuthActions.TypeKeys.VALIDATE_JWT, Catch.deferredAction(_VALIDATE_JWT, io))
  yield takeEvery(Store.AuthActions.TypeKeys.VALIDATE_ORG_USER, Catch.deferredAction(_VALIDATE_ORG_USER, io))
}

export function* _changePassword(io: MiddlewaresIO, action: _AuthActions.ChangePassword) {
  const { oldPassword, newPassword } = action.payload

  const response = yield* call(io.api.auth.changePassword, { old_password: oldPassword, new_password: newPassword })

  return response
}

export function* _FETCH_USER(io: MiddlewaresIO, action: _AuthActions.FetchUser) {
  const username = action.payload

  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.username',
        field: 'table.username',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: username,
      },
    ],
  }

  const query = btoa(JSON.stringify(q))

  const response = yield* call(io.api.auth.fetchUser, query)

  const user = head(response.data)

  if (isNotNil(user)) {
    yield* put(Store.AuthActions.setUser(user))
  }

  return user
}

export function* _GET_WORKATO_JWT(io: MiddlewaresIO, _action: _AuthActions.GET_WORKATO_JWT) {
  return yield* call(io.api.auth.getWorkatoJwt)
}

export function* _UPDATE_USER(io: MiddlewaresIO, action: _AuthActions.UpdateUser) {
  const userName = yield* select(Store.SessionSelectors.selectUsername)

  const { renew, ...user } = action.payload

  yield* call(io.api.auth.updateUser, user)

  const updatedUser = yield* call(
    _FETCH_USER,
    io,
    Store.AuthActions.FETCH_USER(userName, { success: noop, failure: noop }),
  )

  if (isNotNil(renew) && renew) {
    yield* call(io.api.auth.renewJwt)
  }

  return updatedUser
}

export function* _VALIDATE_JWT(io: MiddlewaresIO) {
  const session = yield* call(io.api.auth.validateJWT)

  yield* put(Store.SessionActions.SESSION_SET(session))

  const isTracked = session.tracking ?? true

  if (isTracked && isNotNil(session.id) && isNotNil(session.view_org)) {
    const { config } = getPendoConfig(session)

    yield* call(io.tracker.pendo, config)
  }

  yield* call(io.stdout, 'called validate')

  return session
}

export function* _logIn(io: MiddlewaresIO, action: _AuthActions.LogIn) {
  const { password, username } = action.payload

  const response = yield* call(io.api.auth.loginP, { body: { password, username } })

  const authObj = parseAuth(response.Authorization)

  yield* put(Store.SessionActions.SESSION_SET(authObj))

  yield* call(io.stdout, 'called login')

  return authObj
}

export function* _logOut(io: MiddlewaresIO) {
  yield* call(io.api.auth.logoutP, {})

  yield* put(Store.GlobalActions.reset())
}

export function* _logInOTP(io: MiddlewaresIO, action: _AuthActions.LogInOTP) {
  const response = yield* call(io.api.auth.loginOTP, { body: action.payload })

  const authObj = parseAuth(response.Authorization)

  yield* put(Store.SessionActions.SESSION_SET(authObj))

  return authObj
}

export function* _setPassword(io: MiddlewaresIO, action: _AuthActions.SetPassword) {
  const deleteCookie = () => {
    document.cookie = 'authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  const { password, token } = action.payload

  const username = prop('username', decode<{ username: string }>(token))

  yield* call(io.api.auth.setPassword, { body: { password } })

  // in only this case(activation flow), do we have a cookie that JS can access and delete
  // after resetting, the activation cookie is no longer valid
  yield* call(deleteCookie)

  const response = yield* call(io.api.auth.loginP, { body: { password, username } })

  const authObj = parseAuth(response.Authorization)

  yield* put(Store.SessionActions.SESSION_SET(authObj))

  return authObj
}

export function* _setOTP(io: MiddlewaresIO, action: _AuthActions.SetOTP) {
  const response = yield* call(io.api.auth.loginOTP, { body: action.payload })

  const authObj = parseAuth(response.Authorization)

  yield* put(Store.SessionActions.SESSION_SET(authObj))

  return authObj
}

export function* _getApiTokens(io: MiddlewaresIO) {
  const response = yield* call(io.api.auth.getApiTokens)

  yield* put(Store.AuthActions.API_TOKENS_UPDATE(response.data))

  return response
}

export function* _postApiToken(io: MiddlewaresIO, action: _AuthActions.PostApiToken) {
  const {
    tokens: [newToken],
  } = yield* call(io.api.auth.postApiToken, action.payload)

  const response = yield* call(io.api.auth.getApiTokens)

  yield* put(Store.AuthActions.API_TOKENS_UPDATE(response.data))

  return newToken
}

export function* _deleteApiToken(io: MiddlewaresIO, action: _AuthActions.DeleteApiToken) {
  yield* call(io.api.auth.deleteApiToken, action.payload)

  const response = yield* call(io.api.auth.getApiTokens)

  yield* put(Store.AuthActions.API_TOKENS_UPDATE(response.data))

  return response
}

export function* _resetOTPForUser(io: MiddlewaresIO, action: _AuthActions.ResetOtpForUser) {
  const response = yield* call(io.api.auth.resetOTPToken, {
    target_user_id: action.payload.targetUserId,
    password: action.payload.password,
  })

  return response
}

export function* _REMOVE_USER(io: MiddlewaresIO, action: _AuthActions.RemoveUser) {
  const { authorization } = yield* select(Store.SessionSelectors.selectSession)

  if (!isNotNil(authorization)) {
    throw new Error('state.authorization is null')
  }

  const removeUserPayload: Codecs.RemoveUserPayload = {
    target_user_id: action.payload.targetUserId,
    target_org_id: authorization.view_org ?? '',
  }

  yield* call(io.api.auth.removeUser, removeUserPayload)

  const go = () => {
    io.history.replace(`/${authorization.shortname ?? ''}/settings/team`, {
      triggerRefresh: JSON.stringify(action.payload),
    })
  }

  const { limit, offset, q } = action.payload

  // call saga to fetch users
  yield* call(
    _USERS_FETCH,
    io,
    Store.OrganizationActions.USERS_FETCH({ limit, offset, q }, { success: () => null, failure: () => null }, true),
  )

  yield* call(go)
}

export function* _INVITE_USER(io: MiddlewaresIO, action: _AuthActions.InviteUser) {
  const { authorization } = yield* select(Store.SessionSelectors.selectSession)

  if (!isNotNil(authorization)) {
    throw new Error('state.authorization is null')
  }

  const { inviteUserPayload, usersFetchQueryData } = action.payload

  yield* call(io.api.auth.inviteUser, inviteUserPayload)

  const go = () => {
    io.history.replace(`/${authorization.shortname ?? ''}/settings/team`, {
      triggerRefresh: JSON.stringify(action.payload),
    })
  }

  // call saga to fetch users
  yield* call(
    _USERS_FETCH,
    io,
    Store.OrganizationActions.USERS_FETCH(usersFetchQueryData, { success: () => null, failure: () => null }, true),
  )

  yield* call(go)
}

export function* _RESET_USER_PASSWORD(io: MiddlewaresIO, action: _AuthActions.ResetUserPassword) {
  yield* call(io.api.auth.resetUserPassword, action.payload)
}

export function* _VALIDATE_ORG_USER(io: MiddlewaresIO, action: _AuthActions.VALIDATE_ORG_USER) {
  const userId: string = propOr('', 'id', action.payload.currAuth)
  const userName: string = propOr('', 'username', action.payload.currAuth)

  // update user, re-fetch user
  // -------------------------------------------------------------------------

  yield* call(io.api.auth.updateUser, { view_org: action.payload.nextOrg, id: userId })

  yield* call(_FETCH_USER, io, Store.AuthActions.FETCH_USER(userName, { success: noop, failure: noop }))

  if (isNotNil(action.payload.renew) && action.payload.renew) {
    yield* call(io.api.auth.renewJwt)
  }

  // fetch org
  // -------------------------------------------------------------------------

  const { data: organization } = yield* call(io.api.org.getOrg, action.payload.nextOrg)

  yield* put(Store.OrganizationActions.ORGANIZATION_UPDATE(organization))

  // fetch features and prefs for org
  // -------------------------------------------------------------------------

  yield* all([call(_PREFERENCES_GET, io), call(_FEATURES_FETCH, io)])
}

export function* _CONFIRM_SSO(io: MiddlewaresIO, action: _AuthActions.CONFIRM_SSO) {
  const response = yield* call(io.api.auth.confimSso, action.payload)

  return response
}

export function* _RECOVER_SSO(io: MiddlewaresIO, action: _AuthActions.RECOVER_SSO) {
  const response = yield* call(io.api.auth.recoverSso, action.payload)

  return response
}

export function* _ORG_INIT(io: MiddlewaresIO, action: _AuthActions.ORG_INIT) {
  // fetch org
  // -------------------------------------------------------------------------

  const { data: organization } = yield* call(io.api.org.getOrg, action.payload.org)

  yield* put(Store.OrganizationActions.ORGANIZATION_UPDATE(organization))

  // fetch features and prefs for org
  // -------------------------------------------------------------------------

  const svPayload: _ReconActions.SAVED_VIEWS_FETCH = {
    type: Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH,
    payload: '',
    meta: {
      deferred: {
        success: () => undefined,
        failure: () => undefined,
      },
    },
  }

  yield* all([call(_PREFERENCES_GET, io), call(_FEATURES_FETCH, io), call(_SAVED_VIEWS_FETCH, io, svPayload)])
}

export function* _SSO_FORCE_RELOG(io: MiddlewaresIO, _action: _AuthActions.SSO_FORCE_RELOG) {
  const response = yield* call(io.api.auth.forceRelog)

  return response
}

export function* _SSO_SEND_EMAIL(io: MiddlewaresIO, _action: _AuthActions.SSO_SEND_EMAIL) {
  const response = yield* call(io.api.auth.ssoNotify)

  return response
}

export function* _SSO_DELETE(io: MiddlewaresIO, action: _AuthActions.SSO_DELETE) {
  yield* call(io.api.auth.deleteSso, action.payload)

  const response = yield* call(io.api.auth.getSso)

  yield* put(Store.OrganizationActions.SSO_PROVIDERS_STORE_UPDATE(response))
}

export function* _ACCEPT_TOS(io: MiddlewaresIO, _action: _AuthActions.ACCEPT_TOS) {
  const response = yield* call(io.api.auth.acceptToS)

  const authObj = parseAuth(response.Authorization)

  yield* put(Store.SessionActions.SESSION_SET(authObj))

  return response
}

export function* _REQUEST_PENTEST_POST(io: MiddlewaresIO, action: _AuthActions.REQUEST_PENTEST_POST) {
  const response = yield* call(io.api.auth.postRequestPentest, action.payload)

  return response
}

export function* _GET_PENTEST_METADATA(io: MiddlewaresIO) {
  const response = yield* call(io.api.auth.getPentestMetadata)

  return response
}
