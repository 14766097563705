/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

const _triggerCriteria: Codecs.TriggerCriteria[] = [
  {
    type: 'trigger_criteria',
    attributes: {
      description: 'help me',
      display_value: '',
      field_label: 'field label1',
      field_type: 'list<number>',
      input_variable_path: 'Input.Type',
      is_configurable: false,
      is_matching: true,
      is_standard: false,
      operator: 'in',
      trigger_identifier: 'matching_test',
      value: [22, 23],
      validation: {
        error_text: 'invalid value',
        kind: 'regex',
        value: '.*',
      },
    },
  },
  {
    type: 'trigger_criteria',
    attributes: {
      description: 'help me',
      display_value: '',
      field_label: 'field label2',
      field_type: 'string',
      input_variable_path: 'Input.Type',
      is_configurable: true,
      is_matching: false,
      is_standard: true,
      operator: 'equal',
      trigger_identifier: 'matching_test',
      value: 'text input',
      validation: {
        kind: 'regex',
        value: '.*',
        error_text: 'invalid value',
      },
    },
  },
  {
    type: 'trigger_criteria',
    attributes: {
      description: 'help me',
      display_value: '',
      field_label: 'field label3',
      field_type: 'port',
      input_variable_path: 'Input.Type',
      is_configurable: true,
      is_matching: false,
      is_standard: true,
      operator: 'equal',
      trigger_identifier: 'matching_test',
      value: 8080,
      validation: {
        kind: 'regex',
        value: '.*',
        error_text: 'invalid value',
      },
    },
  },
  {
    type: 'trigger_criteria',
    attributes: {
      description: 'this one has a valid display value',
      display_value: 'Display this, not value',
      field_label: 'field label with display_value',
      field_type: 'string',
      input_variable_path: 'Input.Type',
      is_configurable: false,
      is_matching: true,
      is_standard: true,
      operator: 'equal',
      trigger_identifier: 'matching_test',
      value: 'do not display this',
      validation: {
        kind: 'regex',
        value: '.*',
        error_text: 'invalid value',
      },
    },
  },
]

const _parameters: Codecs.Parameter[] = [
  {
    type: 'parameter',
    attributes: {
      description: 'help me',
      field_label: 'some label',
      field_type: 'number',
      is_configurable: true,
      is_optional: false,
      is_not_set: false,
      parameter_type: 'configuration',
      parameter: 'retries',
      value: 3,
      validation: {
        error_text: 'invalid value',
        kind: 'regex',
        value: '.*',
      },
    },
  },
]

export const ActivityConfiguration1: Codecs.ActivityConfiguration = {
  type: 'activity_configuration',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    activity_type_description: 'DNS Walker',
    activity_type_name: 'DNS Walker',
    authority_display: false,
    authority_distance_max: 1,
    authority_distance_min: 0,
    configuration_id: '682d34f9-bb40-467a-b657-20d491config',
    cves: ['CVE-2020-36736', 'CVE-2021-4386'],
    description: 'Some description',
    enabled: false,
    entities_count: 0,
    last_planned_at: null,
    matching_entity_types: ['org_hostname', 'org_ip'],
    name: 'Config-0-d0ba1753-88bf-4439-a236-e08871d32e1d',
    parameters: _parameters,
    period: 0,
    required_authorization: 0,
    stability: 3,
    stealth: 1,
    targets_count: 0,
    trigger_criteria: _triggerCriteria,
    version: '1.0',
    objective: {
      attackers_perspective: 'Attacker perspective',
      description: 'Objective description',
      implication: 'Objective implication',
    },
    created: {
      date: '2023-08-08T17:29:35.339637Z',
      author_id: 'system',
    },
    updated: {
      date: '2023-08-08T17:29:35.339637Z',
      author_id: 'system',
    },
    mitre: {
      tactics: ['TA0042', 'TA0001'],
      techniques: ['T1548', 'T1134'],
      mitigations: ['M1036', 'M1049'],
    },
  },
}

export const ActivityConfiguration2: Codecs.ActivityConfiguration = {
  type: 'activity_configuration',
  id: '682d34f9-bb40-467a-b657-20d491e90cad',
  attributes: {
    activity_type_description: 'DNS Walker',
    activity_type_name: 'DNS Walker',
    authority_display: false,
    authority_distance_max: 1,
    authority_distance_min: 0,
    configuration_id: '682d34f9-bb40-467a-b657-20d491e90abd',
    cves: ['CVE-2020-36736', 'CVE-2021-4386'],
    description: 'Some description',
    enabled: false,
    entities_count: 0,
    last_planned_at: null,
    matching_entity_types: ['org_hostname', 'org_ip'],
    name: 'Config-0-d0ba1753-88bf-4439-a236-e08871d32e1e',
    parameters: _parameters,
    period: 0,
    required_authorization: 0,
    stability: 3,
    stealth: 1,
    targets_count: 0,
    trigger_criteria: _triggerCriteria,
    version: '1.0',
    objective: {
      attackers_perspective: 'Attacker perspective',
      description: 'Objective description',
      implication: 'Objective implication',
    },
    created: {
      date: '2023-08-08T17:29:35.339637Z',
      author_id: 'system',
    },
    updated: {
      date: '2023-08-08T17:29:35.339637Z',
      author_id: 'system',
    },
    mitre: {
      tactics: ['TA0042', 'TA0001'],
      techniques: ['T1548', 'T1134'],
      mitigations: ['M1036', 'M1049'],
    },
  },
}

export const ActivityConfigurations: Codecs.ActivityConfigurationsResponse = {
  data: [ActivityConfiguration1],
  meta: { total: 1, count: 1, offset: 0 },
  links: {
    self: 'http://example.com/activity-configuration?{query-placeholder}',
    next: 'http://example.com/activity-configuration?{query-placeholder}',
    last: 'http://example.com/activity-configuration?{query-placeholder}',
  },
}

export const ActivityConfiguration: Codecs.ActivityConfigurationResponse = {
  data: ActivityConfiguration1,
  links: {
    self: 'http://example.com/activity-configuration?{query-placeholder}',
  },
}

export const ActivityConfigurationPatch: Codecs.ActivityConfigurationPatchResponse = {
  data: ActivityConfiguration1,
  links: null,
}
