/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  RECON_REPORT_FETCH = 'RECON_REPORT_FETCH',
  RECON_REPORTS_FETCH = 'RECON_REPORTS_FETCH',
  RECON_REPORTS_STORE_UPDATE = 'RECON_REPORTS_STORE_UPDATE',
  RECON_REPORTS_TOTALS_STORE_UPDATE = 'RECON_REPORTS_TOTALS_STORE_UPDATE',
  RECON_REPORTS_TOTALS_FETCH = 'RECON_REPORTS_TOTALS_FETCH',
}

export type ActionTypes =
  | RECON_REPORT_FETCH
  | RECON_REPORTS_FETCH
  | RECON_REPORTS_STORE_UPDATE
  | RECON_REPORTS_TOTALS_FETCH
  | RECON_REPORTS_TOTALS_STORE_UPDATE

// ---------------------------------------------------------------------------

export type RECON_REPORT_FETCH = {
  type: TypeKeys.RECON_REPORT_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function RECON_REPORT_FETCH(payload: RECON_REPORT_FETCH['payload'], deferred: Deferred): RECON_REPORT_FETCH {
  return {
    type: TypeKeys.RECON_REPORT_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_REPORTS_FETCH = {
  type: TypeKeys.RECON_REPORTS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function RECON_REPORTS_FETCH(payload: RECON_REPORTS_FETCH['payload'], deferred: Deferred): RECON_REPORTS_FETCH {
  return {
    type: TypeKeys.RECON_REPORTS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_REPORTS_STORE_UPDATE = {
  type: TypeKeys.RECON_REPORTS_STORE_UPDATE
  payload: Codecs.ReportsResponse
}

export function RECON_REPORTS_STORE_UPDATE(payload: RECON_REPORTS_STORE_UPDATE['payload']): RECON_REPORTS_STORE_UPDATE {
  return {
    type: TypeKeys.RECON_REPORTS_STORE_UPDATE,
    payload,
  }
}

type RECON_REPORTS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.RECON_REPORTS_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function RECON_REPORTS_TOTALS_STORE_UPDATE(
  payload: RECON_REPORTS_TOTALS_STORE_UPDATE['payload'],
): RECON_REPORTS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.RECON_REPORTS_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type RECON_REPORTS_TOTALS_FETCH = {
  type: TypeKeys.RECON_REPORTS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function RECON_REPORTS_TOTALS_FETCH(
  payload: RECON_REPORTS_TOTALS_FETCH['payload'],
  deferred: Deferred,
): RECON_REPORTS_TOTALS_FETCH {
  return {
    type: TypeKeys.RECON_REPORTS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}
