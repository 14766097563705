/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { OrganizationResponse } from '@/api/organization'
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import {
  PentestMetadata,
  FormValues as RequestPentestFormValues,
} from '@/pages/entity-detail/target/request-pen-test-modal/request-pen-test.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type AuthApi = {
  acceptToS: () => Promise<Codecs.LoginResponse>
  changePassword: (body: { old_password: string; new_password: string }) => Promise<unknown>
  confimSso: (body: Codecs.SsoConfirm) => Promise<Codecs.SsoConfirmResponse>
  deleteApiToken: (id: string) => Promise<unknown>
  deleteSso: (alias: string) => Promise<unknown>
  fetchUser: (query: string) => Promise<Codecs.FetchUserResponse>
  forceRelog: () => Promise<unknown>
  getApiTokens: () => Promise<Codecs.ApiTokensResponse>
  getOTPSecret: () => Promise<Codecs.NewOtpTokenResponse>
  getOrgFeatures: () => Promise<Codecs.OrgFeaturesResponse>
  getOrgUsers: (query: string) => Promise<Codecs.OrgUsersResponse>
  getPentestMetadata: () => Promise<PentestMetadata>
  getPermissionGroupTypes: () => Promise<Codecs.PermissionGroupTypesResponse>
  getPermissionGroups: (id: string) => Promise<Codecs.PermissionGroupsResponse>
  getSso: () => Promise<Codecs.SsoProvidersResponse>
  getWorkatoDetail: () => Promise<Codecs.WorkatoDetail>
  getWorkatoJwt: () => Promise<Codecs.LoginResponse>
  inviteUser: (payload: Codecs.InviteUserPayload) => Promise<Codecs.InviteUserResponse>
  loginOTP: ({ body }: { body: { otp: string } }) => Promise<Codecs.LoginResponse>
  loginP: ({ body }: { body: { password: string; username: string } }) => Promise<Codecs.LoginResponse>
  logoutP: (body: Record<string, unknown>) => Promise<unknown>
  parseIdpXml: (payload: unknown) => Promise<Codecs.ParsedIDPResponse>
  patchOrg: (id: string, payload: Codecs.PatchOrganizationPayload) => Promise<OrganizationResponse>
  postApiToken: (body: Codecs.ApiTokenPayload) => Promise<Codecs.ApiTokenPostResponse>
  postSso: (payload: unknown) => Promise<Codecs.SsoProvider>
  postRequestPentest: (payload: RequestPentestFormValues) => Promise<unknown>
  recoverSso: (body: Codecs.SsoRecover) => Promise<unknown>
  removeUser: (payload: Codecs.RemoveUserPayload) => Promise<unknown>
  renewJwt: () => Promise<Codecs.RenewResponse>
  resetOTPToken: (payload: { target_user_id: string; password: string }) => Promise<void>
  resetUserPassword: (payload: Codecs.ResetUserPasswordPayload) => Promise<Codecs.ResetUserPasswordResponse>
  setPassword: ({ body }: { body: { password: string } }) => Promise<unknown>
  ssoNotify: () => Promise<unknown>
  updatePermissionGroups: (body: Codecs.PermissionGroupsPayload) => Promise<Codecs.PermissionGroupsResponse>
  updateUser: (user: Codecs.UpdateUserPayload) => Promise<unknown>
  validateJWT: () => Promise<Codecs.ValidateJWTResponse>
}

// ---------------------------------------------------------------------------

export function authApiFactory(http: HttpClient): AuthApi {
  const baseURL = '/auth/api/v1'

  return {
    postRequestPentest(payload) {
      return http.post(`${baseURL}/request-pentest`, payload)
    },

    getPentestMetadata() {
      return http.get(`${baseURL}/pentest-metadata`)
    },

    deleteApiToken(id) {
      return http.delete(`${baseURL}/api-token/${id}`)
    },

    getApiTokens() {
      return http.get(`${baseURL}/api-token`)
    },

    postApiToken(body) {
      return http.post(`${baseURL}/api-token`, body)
    },

    getPermissionGroupTypes() {
      return http
        .get(`${baseURL}/permission-group-types`)
        .then(CodecUtils.throwValidate(Codecs.PermissionGroupTypesResponse, 'PermissionGroupTypesResponse'))
    },

    getPermissionGroups(id) {
      return http
        .get(`${baseURL}/permission-groups?target_user_id=${id}`)
        .then(CodecUtils.throwValidate(Codecs.PermissionGroupsResponse, 'PermissionGroupsResponse'))
    },

    getWorkatoJwt() {
      return http
        .get(`${baseURL}/integrations-jwt`)
        .then(CodecUtils.throwValidate(Codecs.LoginResponse, 'LoginResponse'))
    },

    updatePermissionGroups(body) {
      return http
        .post(`${baseURL}/permission-groups`, body)
        .then(CodecUtils.throwValidate(Codecs.PermissionGroupsResponse, 'PermissionGroupsResponse'))
    },

    changePassword(body) {
      return http.post(`${baseURL}/change-password`, body)
    },

    fetchUser(query) {
      return http.get(`${baseURL}/user?q=${query}`)
    },

    renewJwt() {
      return http.post(`${baseURL}/renew`, {}).then(CodecUtils.throwValidate(Codecs.RenewResponse, 'RenewResponse'))
    },

    setPassword({ body }) {
      return http.post(`${baseURL}/reset-password`, { password: body.password })
    },

    getOrgFeatures() {
      return http
        .get(`${baseURL}/features-org`)
        .then(CodecUtils.throwValidate(Codecs.OrgFeaturesResponse, 'OrgFeaturesResponse'))
    },

    resetOTPToken(payload) {
      return http.post(`${baseURL}/reset-otp-token`, payload)
    },

    getOTPSecret() {
      return http.post(`${baseURL}/new-otp-token`, {})
    },

    getOrgUsers(query) {
      return http
        .get(`${baseURL}/user${query}`)
        .then(CodecUtils.throwValidate(Codecs.OrgUsersResponse, 'OrgUsersResponse'))
    },

    patchOrg(id, payload) {
      return http
        .patch(`${baseURL}/organization/${id}`, payload)
        .then(CodecUtils.throwValidate(OrganizationResponse, 'OrganizationResponse'))
    },

    loginP({ body }) {
      return http.post(`${baseURL}/login`, body)
    },

    logoutP(body) {
      return http.post(`${baseURL}/logout`, { ...body })
    },

    getWorkatoDetail() {
      return http
        .get(`${baseURL}/org-workato-details`)
        .then(CodecUtils.throwValidate(Codecs.WorkatoDetail, 'WorkatoDetail'))
    },

    loginOTP({ body }) {
      return http.post(`${baseURL}/login-otp`, { ...body })
    },

    updateUser(user) {
      const { id, ...rest } = user
      const { password, ...userData } = rest

      return http.patch(`${baseURL}/user/${id}`, { data: userData, confirmed_password: password })
    },

    validateJWT() {
      return http
        .get(`${baseURL}/validate?check_stasis=true`)
        .then(CodecUtils.throwValidate(Codecs.ValidateJWTResponse, 'ValidateJWTResponse'))
    },

    removeUser(payload) {
      return http.post(`${baseURL}/unlink-user`, payload)
    },

    inviteUser(payload) {
      return http.post(`${baseURL}/invite-user`, payload)
    },

    resetUserPassword(payload) {
      return http.post(`${baseURL}/send-user-reset-token`, payload)
    },

    getSso() {
      return http
        .get(`${baseURL}/sso-connections`)
        .then(CodecUtils.throwValidate(Codecs.SsoProvidersResponse, 'SsoProvidersResponse'))
    },

    postSso(payload) {
      return http
        .post(`${baseURL}/sso-connections`, payload)
        .then(CodecUtils.throwValidate(Codecs.SsoProvider, 'SsoProvider'))
    },

    confimSso(body) {
      return http.post(`${baseURL}/confirm-sso`, body)
    },

    recoverSso(body) {
      return http.post(`${baseURL}/recover-sso`, body)
    },

    parseIdpXml(payload) {
      return http
        .post(`${baseURL}/sso-parse-metadata`, payload)
        .then(CodecUtils.throwValidate(Codecs.ParsedIDPResponse, 'ParsedIDPResponse'))
    },

    ssoNotify: () => {
      return http.post(`${baseURL}/notify-org-users-sso`, {})
    },

    forceRelog: () => {
      return http.post(`${baseURL}/logout-org-users`, {})
    },

    deleteSso: (alias) => {
      return http.delete(`${baseURL}/sso-connections?alias=${alias}`)
    },

    acceptToS: () => {
      return http.post(`${baseURL}/confirm-tos`, {})
    },
  }
}
